//Khadz-burger/src/Auth/AuthApi.js
import axios from 'axios';

// Base URL for API (use environment variable for API endpoint)
const API_URL = process.env.REACT_APP_API_URL;

// Authentication API
export const AuthApi = {
  // Login function
  login: async (email, password) => {
    try {
      const response = await axios.post(`${API_URL}/auth/login`, { email, password });
      return response.data;
    } catch (error) {
      console.error('Login error details:', error.response?.data || error.message); // Debugging log
      throw new Error(error.response?.data?.error || 'An unexpected error occurred during login');
    }
  },

  // Signup function
  signup: async (name, email, password) => {
    try {
      const response = await axios.post(`${API_URL}/auth/signup`, { name, email, password });
      return response.data;
    } catch (error) {
      // Return the backend error message if available
      throw new Error(error.response?.data?.error || 'An unexpected error occurred during signup');
    }
  },

  // Get current user function (could be from localStorage or API)
  getCurrentUser: async () => {
    const storedUser = localStorage.getItem('authUser');
    if (storedUser) {
      return JSON.parse(storedUser);
    }
    throw new Error('No user logged in');
  },
};
