// src/Pages/Chat/ChatHeader.js
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';

export const ChatHeader = ({ setChatMinimized, chatMinimized, setIsOpen }) => {
  return (
    <div className="chat-header">
      <h3>Live Chat</h3>
      <div>
        <button className="minimize-btn" onClick={() => setChatMinimized(!chatMinimized)}>
          {chatMinimized ? <FontAwesomeIcon icon={faChevronUp} /> : <FontAwesomeIcon icon={faChevronDown} />}
        </button>
        <button onClick={() => setIsOpen(false)} className="close-btn">&times;</button>
      </div>
    </div>
  );
};
