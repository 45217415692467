import React, { createContext, useState } from 'react';

export const CartContext = createContext();

export const CartProvider = ({ children }) => {
  const [cart, setCart] = useState([]);

  const addToCart = (product, quantity) => {
    if (quantity <= 0) {
      setCart(cart.filter(item => item.id !== product.id));
    } else {
      const existingProduct = cart.find(item => item.id === product.id);
      if (existingProduct) {
        setCart(cart.map(item =>
          item.id === product.id ? { ...item, quantity } : item
        ));
      } else {
        setCart([...cart, { ...product, quantity }]);
      }
    }
  };

  const removeFromCart = (id) => {
    setCart(cart.filter(item => item.id !== id));
  };

  const clearCart = () => {
    setCart([]);
  };

  return (
    <CartContext.Provider value={{ cart, addToCart, removeFromCart, clearCart }}>
      {children}
    </CartContext.Provider>
  );
};
