import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './Pages/Home';
import './App.css';
import { CartProvider } from './Context/CartContext';
import { NotificationProvider } from './Context/NotificationContext';
import { FavoritesProvider } from './Context/FavoritesContext';
import { AuthProvider } from './Auth/AuthContext';
import Dashboard from './Dashboard/Dashboard';
import AdminDashboard from './Admin/AdminDashboard'; // Import the Admin dashboard
import AdminLogin from './Pages/Account/AdminLogin'; // Import AdminLogin page
import PrivateRoute from './Auth/PrivateRoute';

function App() {
  return (
    <NotificationProvider>
      <CartProvider>
        <FavoritesProvider>
          <Router>
            <AuthProvider>
              <div className="App">
                <Routes>
                  <Route path="/" element={<Home />} />
                  
                  {/* User Dashboard (protected route) */}
                  <Route
                    path="/dashboard"
                    element={
                      <PrivateRoute role="user">
                        <Dashboard />
                      </PrivateRoute>
                    }
                  />
                  
                  {/* Admin Dashboard (protected route) */}
                  <Route
                    path="/admin-dashboard"
                    element={
                      <PrivateRoute role="admin">
                        <AdminDashboard />
                      </PrivateRoute>
                    }
                  />

                  {/* Admin Login Route */}
                  <Route path="/admin-login" element={<AdminLogin />} />

                  {/* Add more routes as necessary */}
                </Routes>
              </div>
            </AuthProvider>
          </Router>
        </FavoritesProvider>
      </CartProvider>
    </NotificationProvider>
  );
}

export default App;
