// src/Utils/Products.js

export const burgers = [
  { id: 9, name: 'Classic Burger', price: 7400.99, image: require('../Assets/burgers/classic-burger.jpg') },
  { id: 10, name: 'Cheese Burger', price: 7000.99, image: require('../Assets/burgers/veggie-special.jpg') },
];

export const fries = [
  { id: 1, name: 'Classic Fries', price: 2300.99, image: require('../Assets/fries/classic-fries.webp') },
  { id: 2, name: 'Cheese Fries', price: 3000.99, image: require('../Assets/fries/cheese-fries.webp') },
];

export const fruitParfaits = [
  { id: 3, name: 'Strawberry Parfait', price: 2200.99, image: require('../Assets/parfaits/strawberry-parfait.webp') },
  { id: 4, name: 'Blueberry Parfait', price: 3000.99, image: require('../Assets/parfaits/blueberry-parfait.webp') },
];

export const drinks = [
  { id: 5, name: 'Coca Cola', price: 1200.99, image: require('../Assets/drinks/coca-cola.webp') },
  { id: 6, name: 'Lemonade', price: 1400.99, image: require('../Assets/drinks/lemonade.webp') },
];

export const milkshakes = [
  { id: 7, name: 'Chocolate Milkshake', price: 3250.99, image: require('../Assets/milkshakes/chocolate-milkshake.webp') },
  { id: 8, name: 'Vanilla Milkshake', price: 3500.99, image: require('../Assets/milkshakes/vanilla-milkshake.webp') },
];

// Combine all products into a single array
export const products = [
  ...burgers,
  ...fries,
  ...fruitParfaits,
  ...drinks,
  ...milkshakes,
];
