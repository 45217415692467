// src/Pages/Chat/ChatLogic.js
import { useState } from 'react';
import notificationResponseSound from '../../Assets/Tones/notification4.mp3';

export const useChatLogic = () => {
  const [messages, setMessages] = useState([]);
  const [input, setInput] = useState('');
  const [currentFlow, setCurrentFlow] = useState(null); // Track current conversation flow
  const responseSound = new Audio(notificationResponseSound);

  const handlePredefinedAction = (action) => {
    switch (action.toLowerCase()) {
      case 'check order status':
        setCurrentFlow('checkOrderStatus');
        return "Please provide your Order Number, and we’ll check the status.";
      case 'cancel order':
        setCurrentFlow('cancelOrder');
        return "To cancel your order, please provide your Order Number.";
      case 'make an inquiry':
        setCurrentFlow('inquiry');
        return "What would you like to inquire about? Feel free to ask us anything.";
      case 'complaint about delivery':
        setCurrentFlow('complaint');
        return "We’re sorry about your delivery experience. Please describe the issue.";
      case 'back to options':
        setCurrentFlow(null);
        return showPredefinedOptions(); // Function to show options again
      default:
        return "How can we assist you today?";
    }
  };

  const showPredefinedOptions = () => {
    setMessages([
      { sender: 'bot', text: "How can we assist you today? Please select an option:" },
      { sender: 'predefined', text: "Check Order Status" },
      { sender: 'predefined', text: "Cancel Order" },
      { sender: 'predefined', text: "Make an Inquiry" },
      { sender: 'predefined', text: "Complaint about Delivery" },
    ]);
  };

  const handlePredefinedClick = (message) => {
    const newMessage = { sender: 'user', text: message };
    setMessages((prevMessages) => [...prevMessages, newMessage]);

    setTimeout(() => {
      const predefinedResponse = handlePredefinedAction(message);
      setMessages((prevMessages) => [
        ...prevMessages,
        { sender: 'bot', text: predefinedResponse },
      ]);
      responseSound.play(); // Play response notification sound
    }, 1000);
  };

  const handleSend = () => {
    if (input.trim()) {
      const newMessage = { sender: 'user', text: input };
      setMessages((prevMessages) => [...prevMessages, newMessage]);

      setTimeout(() => {
        let predefinedResponse = "";
        if (currentFlow === 'checkOrderStatus') {
          predefinedResponse = `Order ${input} is currently being processed.`;
        } else if (currentFlow === 'cancelOrder') {
          predefinedResponse = `Order ${input} has been cancelled successfully.`;
        } else if (currentFlow === 'inquiry') {
          predefinedResponse = `We have received your inquiry: "${input}". Our team will get back to you shortly.`;
        } else if (currentFlow === 'complaint') {
          predefinedResponse = `We have received your complaint: "${input}". We apologize for the inconvenience.`;
        } else {
          predefinedResponse = handlePredefinedAction(input.toLowerCase());
        }

        setMessages((prevMessages) => [
          ...prevMessages,
          { sender: 'bot', text: predefinedResponse },
        ]);
        responseSound.play(); // Play response notification sound
      }, 1000);

      setInput(''); // Clear the input field
    }
  };

  return {
    messages,
    input,
    setInput,
    handleSend,
    handlePredefinedClick,
    showPredefinedOptions,
  };
};
