import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGift, faBell, faThumbsUp, faBoxOpen, faHeart } from '@fortawesome/free-solid-svg-icons'; // Import icons
import './Benefits.css';  // Import the corresponding CSS file for styling

const Benefits = () => {
  return (
    <div className="benefits-container">
      <h2>Why Join Us?</h2>
      <ul className="benefits-list">
        <li>
          <FontAwesomeIcon icon={faGift} className="benefit-icon" />
          Exclusive rewards for loyal customers.
        </li>
        <li>
          <FontAwesomeIcon icon={faBell} className="benefit-icon" />
          Get updates on new products and special deals.
        </li>
        <li>
          <FontAwesomeIcon icon={faThumbsUp} className="benefit-icon" />
          Personalized product recommendations.
        </li>
        <li>
          <FontAwesomeIcon icon={faBoxOpen} className="benefit-icon" />
          Easy order tracking and history.
        </li>
        <li>
          <FontAwesomeIcon icon={faHeart} className="benefit-icon" />
          Save favorite items for quick reordering.
        </li>
      </ul>
    </div>
  );
};

export default Benefits;
