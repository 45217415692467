// src/Context/NotificationContext.js
import React, { createContext, useState } from 'react';
import './Notification.css'

export const NotificationContext = createContext();

export const NotificationProvider = ({ children }) => {
  const [notification, setNotification] = useState({ message: '', type: '' });
  const [isVisible, setIsVisible] = useState(false);

  const showNotification = (message, type = 'success') => {
    setNotification({ message, type });
    setIsVisible(true);
    setTimeout(() => {
      setIsVisible(false);
    }, 5000);  // Automatically hide after 5 seconds
  };

  return (
    <NotificationContext.Provider value={{ showNotification }}>
      {children}
      {isVisible && (
        <div className={`notification ${notification.type} ${isVisible ? 'show' : ''}`}>
          <p>{notification.message}</p>
        </div>
      )}
    </NotificationContext.Provider>
  );
};
