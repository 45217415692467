import React from 'react';
import './Confirmation.css';

const Confirmation = ({ orderId, setActiveSection }) => {
  const handleCreateAccountClick = () => {
    setActiveSection('account'); // Navigate to the account section
  };

  return (
    <div className="confirmation-container">
      <div className="confirmation-content">
        <h2>Thank You for Your Order!</h2>
        <p>Your order has been placed successfully.</p>
        <p>
          Order ID: <strong className="order-id">{orderId}</strong>
        </p>
        <p>Order Status: <strong>Pending Payment Confirmation</strong></p>
        <p>We will contact you shortly with the delivery time estimate.</p>

        {/* Add create account prompt for unauthenticated users */}
        <div className="account-prompt">
          <p>Want to track your orders easily and get more benefits?</p>
          <button className="create-account-btn" onClick={handleCreateAccountClick}>
            Create an Account
          </button>
        </div>
      </div>
    </div>
  );
};

export default Confirmation;
