// src/Pages/Footer/Footer.js
import React from 'react';

const Footer = () => {
  return (
    <footer style={styles.footer}>
      <p>&copy; 2024 Khadz Burger. All Rights Reserved.</p>
    </footer>
  );
};

const styles = {
  footer: {
    backgroundColor: '#000',  // Black footer
    color: '#FFD700',         // Yellow text for footer
    padding: '20px',
    textAlign: 'center',
  },
};

export default Footer;
