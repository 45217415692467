import React, { useContext } from 'react';
import { CartContext } from '../../Context/CartContext';
import { FavoritesContext } from '../../Context/FavoritesContext'; 
import { products } from '../../Utils/Products'; 
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faShoppingCart } from '@fortawesome/free-solid-svg-icons';
import './Cart.css';

const Cart = ({ setActiveSection }) => {
  const { cart, removeFromCart, clearCart, addToCart } = useContext(CartContext);
  const { favorites } = useContext(FavoritesContext);

  const totalPrice = cart.reduce((total, item) => total + item.price * item.quantity, 0);

  const getRandomProducts = () => {
    const shuffled = [...products].sort(() => 0.5 - Math.random());
    return shuffled.slice(0, 4);
  };

  return (
    <div className="cart-container">
      <h2>Your Cart</h2>

      {cart.length === 0 ? (
        <div className="empty-cart">
          <p>Your cart is empty.</p>
          <h3>Check out some of your favorite products!</h3>
          <div className="random-products">
            {favorites.length > 0 ? (
              favorites.map((product) => (
                <div key={product.id} className="random-product-card">
                  <img src={product.image} alt={product.name} className="random-product-image" />
                  <div className="random-product-info">
                    <h4>{product.name}</h4>
                    <p>₦{product.price.toFixed(2)}</p>
                    <button className="add-to-cart-btn" onClick={() => addToCart(product, 1)}>
                      Add to Cart
                    </button>
                  </div>
                </div>
              ))
            ) : (
              <p>No favorites yet. Start adding some!</p>
            )}
          </div>
        </div>
      ) : (
        <>
          <div className="cart-items">
            {cart.map(item => (
              <div key={item.id} className="cart-item">
                <img src={item.image} alt={item.name} className="cart-item-image" />
                <div className="cart-item-details">
                  <h3>{item.name}</h3>
                  <p>Price: ₦{item.price.toFixed(2)}</p>
                  <div className="quantity-control">
                    <button className="quantity-btn" onClick={() => addToCart(item, item.quantity - 1)}>-</button>
                    <span>{item.quantity}</span>
                    <button className="quantity-btn" onClick={() => addToCart(item, item.quantity + 1)}>+</button>
                  </div>
                  <p>Total: ₦{(item.price * item.quantity).toFixed(2)}</p>
                </div>
                <button className="remove-item-btn" onClick={() => removeFromCart(item.id)}>
                  <FontAwesomeIcon icon={faTrash} /> Remove
                </button>
              </div>
            ))}
          </div>

          <div className="cart-total">
            <h3>Total: ₦{totalPrice.toFixed(2)}</h3>
          </div>

          <div className="cart-actions">
            <button className="clear-cart-btn" onClick={clearCart}>
              <FontAwesomeIcon icon={faTrash} /> Clear Cart
            </button>
            <button className="checkout-btn" onClick={() => setActiveSection('checkout')}>
              <FontAwesomeIcon icon={faShoppingCart} /> Checkout
            </button>
          </div>

          <div className="suggested-products">
            <h3>You might also like:</h3>
            <div className="random-products">
              {getRandomProducts().map((product) => (
                <div key={product.id} className="random-product-card">
                  <img src={product.image} alt={product.name} className="random-product-image" />
                  <div className="random-product-info">
                    <h4>{product.name}</h4>
                    <p>₦{product.price.toFixed(2)}</p>
                    <button className="add-to-cart-btn" onClick={() => addToCart(product, 1)}>
                      Add to Cart
                    </button>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default Cart;
