// src/Pages/Chat/liveChat.js
import React, { useState } from 'react';
import './liveChat.css'; // Import CSS for styling
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faComments } from '@fortawesome/free-solid-svg-icons';
import { ChatHeader } from './ChatHeader';
import { ChatBody } from './ChatBody';
import { ChatFooter } from './ChatFooter';
import { useChatLogic } from './ChatLogic';
import notificationSound from '../../Assets/Tones/notification.mp3';

const LiveChat = () => {
  const [isOpen, setIsOpen] = useState(false); // To toggle the chat window
  const [chatMinimized, setChatMinimized] = useState(false);
  const [chatPosition, setChatPosition] = useState({ right: 20, bottom: 100 });
  const { messages, input, setInput, handleSend, handlePredefinedClick, showPredefinedOptions } = useChatLogic();
  const notification = new Audio(notificationSound);

  // Auto scroll logic can be inside ChatBody.

  const handleDrag = (e) => {
    const newX = window.innerWidth - e.clientX;
    const newY = window.innerHeight - e.clientY;
    setChatPosition({ right: newX, bottom: newY });
  };

  const openChat = () => {
    setIsOpen(true);
    notification.play();
    if (messages.length === 0) showPredefinedOptions();
  };

  return (
    <div className="live-chat-container" style={{ right: `${chatPosition.right}px`, bottom: `${chatPosition.bottom}px` }}>
      <button className="chat-icon" draggable="true" onDrag={handleDrag} onClick={openChat}>
        <FontAwesomeIcon icon={faComments} />
      </button>

      {isOpen && (
        <div className={`chat-window ${chatMinimized ? 'minimized' : ''}`}>
          <ChatHeader setChatMinimized={setChatMinimized} chatMinimized={chatMinimized} setIsOpen={setIsOpen} />
          {!chatMinimized && (
            <>
              <ChatBody messages={messages} handlePredefinedClick={handlePredefinedClick} />
              <ChatFooter input={input} setInput={setInput} handleSend={handleSend} />
            </>
          )}
        </div>
      )}
    </div>
  );
};

export default LiveChat;
