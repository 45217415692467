// src/Sales/SalesFilters.js
import React from 'react';
import './Sales.css';

const SalesFilters = ({ filter, setFilter }) => {
  return (
    <div className="sales-filters">
      <button
        onClick={() => setFilter('daily')}
        className={filter === 'daily' ? 'active-filter' : ''}
      >
        Daily
      </button>
      <button
        onClick={() => setFilter('weekly')}
        className={filter === 'weekly' ? 'active-filter' : ''}
      >
        Weekly
      </button>
      <button
        onClick={() => setFilter('monthly')}
        className={filter === 'monthly' ? 'active-filter' : ''}
      >
        Monthly
      </button>
    </div>
  );
};

export default SalesFilters;
