import React, { useState, useContext } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faEnvelope, faLock, faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import './Account.css';
import { NotificationContext } from '../../Context/NotificationContext';
import Loader from '../../Loader/Loader'; // Import the Loader

const SignUp = ({ switchPage, signup }) => {
  const [showPassword, setShowPassword] = useState(false);
  const { showNotification } = useContext(NotificationContext); // Access notification context
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [name, setName] = useState('');
  const [loading, setLoading] = useState(false); // Loading state for the loader

  // Function to handle the signup process
  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log("Signup form submitted with: ", { name, email, password });
    setLoading(true); // Show loader during signup process

    try {
      const response = await signup(name, email, password); // Call signup
      console.log("Signup successful: ", response);
      showNotification('Signup successful!', 'success'); // Notify on success
      switchPage('signin'); // Switch to sign-in page on success
    } catch (error) {
      // Get specific error message from the backend or show generic message
      const errorMessage = error.message || 'An error occurred during signup. Please try again later.';
      console.error("Signup error: ", errorMessage);
      showNotification(errorMessage, 'error'); // Show specific error message
    } finally {
      setLoading(false); // Hide loader after signup
    }
  };

  // Function to toggle password visibility
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <div className="account-container">
      <h2>Sign Up</h2>
      {loading ? (
        <Loader /> // Show loader when loading state is true
      ) : (
        <form onSubmit={handleSubmit}>
          {/* Full Name Field */}
          <label>Full Name:</label>
          <div className="input-wrapper">
            <FontAwesomeIcon icon={faUser} className="input-icon" />
            <input 
              type="text" 
              name="name" 
              placeholder="Enter your full name" 
              value={name}
              onChange={(e) => setName(e.target.value)} 
              required 
            />
          </div>

          {/* Email Field */}
          <label>Email:</label>
          <div className="input-wrapper">
            <FontAwesomeIcon icon={faEnvelope} className="input-icon" />
            <input 
              type="email" 
              name="email" 
              placeholder="Enter your email"
              value={email}
              onChange={(e) => setEmail(e.target.value)} 
              required 
            />
          </div>

          {/* Password Field */}
          <label>Password:</label>
          <div className="input-wrapper">
            <FontAwesomeIcon icon={faLock} className="input-icon" />
            <input
              type={showPassword ? "text" : "password"}
              name="password"
              placeholder="Enter your password"
              value={password}
              onChange={(e) => setPassword(e.target.value)} 
              required
            />
            <FontAwesomeIcon
              icon={showPassword ? faEyeSlash : faEye}
              className="toggle-password-icon"
              onClick={togglePasswordVisibility}
            />
          </div>

          {/* Submit Button */}
          <button type="submit">Sign Up</button>
        </form>
      )}
      <p>
        Already have an account? <button onClick={() => switchPage('signin')}>Sign In</button>
      </p>
    </div>
  );
};

export default SignUp;
