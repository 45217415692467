//src/Loader/Loader.js

import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHamburger, faIceCream, faUtensils } from '@fortawesome/free-solid-svg-icons'; // Import icons
import './Loader.css'; // Import styles for the loader

const Loader = () => {
  return (
    <div className="loader-container">
      <div className="loader">
        <FontAwesomeIcon icon={faHamburger} className="loader-icon hamburger" />
        <FontAwesomeIcon icon={faIceCream} className="loader-icon ice-cream" />
        <FontAwesomeIcon icon={faUtensils} className="loader-icon utensils" />
      </div>
    </div>
  );
};

export default Loader;
