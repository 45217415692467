import React, { useState, useContext } from 'react';
import { AuthContext } from '../../Auth/AuthContext'; // Import the AuthContext
import { useNavigate } from 'react-router-dom';
import './AdminLogin.css'; // Import the CSS file
import Loader from '../../Loader/Loader'; // Import the existing loader

const AdminLogin = () => {
  const { login } = useContext(AuthContext); // Use login function from AuthContext
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const handleAdminLogin = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(null);

    try {
      await login(email, password); // Use the existing login function from AuthContext
      const storedUser = JSON.parse(localStorage.getItem('authUser'));

      if (storedUser && storedUser.role === 'admin') {
        navigate('/admin-dashboard'); // Redirect admin to AdminDashboard
      } else {
        setError('You do not have admin access.');
      }
    } catch (err) {
      setError(err.message || 'An error occurred during login.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="admin-login-container">
      {loading && <Loader />} {/* Display the loader independently */}

      {!loading && (
        <div className="admin-login-form">
          <h2>Admin Login</h2>
          <form onSubmit={handleAdminLogin}>
            <div className="input-group">
              <input
                type="email"
                className="admin-login-input"
                placeholder="Admin Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
            </div>

            <div className="input-group">
              <input
                type={showPassword ? 'text' : 'password'}
                className="admin-login-input"
                placeholder="Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
              />
              <span
                className="password-toggle"
                onClick={() => setShowPassword(!showPassword)}
              >
                {showPassword ? 'Hide' : 'Show'}
              </span>
            </div>

            <button type="submit" className="admin-login-button">
              Login
            </button>
          </form>

          {error && <p className="admin-login-error">{error}</p>}
        </div>
      )}
    </div>
  );
};

export default AdminLogin;
