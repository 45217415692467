import axios from 'axios';

const apiUrl = process.env.REACT_APP_API_URL;

export const SalesApi = {
  getSalesData: async (filter) => {
    try {
      const token = localStorage.getItem('authToken'); // Retrieve the token from localStorage

      const response = await axios.get(`${apiUrl}/admin/sales?filter=${filter}`, {
        headers: {
          Authorization: `Bearer ${token}`, // Include the token in the request headers
        },
      });

      return response.data;
    } catch (error) {
      console.error('Error fetching sales data:', error);
      throw error;
    }
  },
};


export default SalesApi;
