import React, { useState, useEffect, useRef, useContext } from 'react';
import Layout from '../Layout/Layout';
import Hero from './Hero/Hero';
import FilterableProductList from '../ProductSection/FilterableProductList';
import Favorites from './Favorites/Favorites';
import Cart from './Cart/Cart';
import Account from './Account/Account';
import Review from '../Reviews/Reviews';
import Checkout from './Checkout/Checkout';
import Confirmation from './Confirmation/Confirmation';
import LiveChat from './Chat/liveChat';  // Import LiveChat component
import { checkApiConnection } from '../Api/MainApi';  // Import the connection checker
import { AuthContext } from '../Auth/AuthContext';  // Import AuthContext

const Home = () => {
  const [activeSection, setActiveSection] = useState('home');
  const [orderId, setOrderId] = useState(''); // Store Order ID
  const [apiStatus, setApiStatus] = useState(null); // Track API connection status
  const { currentUser } = useContext(AuthContext); // Get the current user from AuthContext
  const intervalRef = useRef(null); // To track the interval

  // Function to check API connection
  const checkConnection = async () => {
    const result = await checkApiConnection();
    if (result.success) {
      setApiStatus('Connected');
    } else {
      setApiStatus('Connection Failed');
    }
  };

  // Check API connection when the component mounts, and every 5 minutes
  useEffect(() => {
    checkConnection(); // Initial check on component mount

    if (!intervalRef.current) {
      intervalRef.current = setInterval(() => {
        checkConnection(); // Check every 5 minutes
      }, 300000); // 5 minutes in milliseconds (300,000 ms)
    }

    return () => clearInterval(intervalRef.current); // Cleanup interval on unmount
  }, []);

  const renderContent = () => {
    switch (activeSection) {
      case 'home':
        return (
          <>
            <Hero />
            <FilterableProductList />
          </>
        );
      case 'favorites':
        return <Favorites />;
      case 'cart':
        return <Cart setActiveSection={setActiveSection} />;
      case 'account':
        return <Account currentUser={currentUser} />; 
      case 'reviews':
        return <Review />;
      case 'checkout':
        return (
          <Checkout
            setActiveSection={(section, { orderId }) => {
              setOrderId(orderId); // Set the order ID when navigating to confirmation
              setActiveSection(section);
            }}
          />
        );
      case 'confirmation':
        return (
          <Confirmation orderId={orderId} setActiveSection={setActiveSection} />
        ); // Pass setActiveSection to Confirmation
      default:
        return null;
    }
  };

  return (
    <Layout setActiveSection={setActiveSection}>
      {renderContent()}
      <LiveChat /> {/* Add LiveChat component here */}
    </Layout>
  );
};

export default Home;
