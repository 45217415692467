import React from 'react';
import Header from '../Header/Header';  // Import the Header component
import './Layout.css';  // Import CSS for layout

const Layout = ({ children }) => {
  return (
    <div className="dashboard-layout">
      <Header />
      <div className="dashboard-content">
        {children}  {/* This will render the content passed to the layout */}
      </div>
    </div>
  );
};

export default Layout;
