import React, { useContext } from 'react';
import { AuthContext } from '../Auth/AuthContext'; // Import AuthContext
import { useNavigate } from 'react-router-dom'; // For navigation
import Layout from './Layout/Layout'; // Import Layout component

const Dashboard = () => {
  const { currentUser, logout } = useContext(AuthContext); // Get current user and logout function
  const navigate = useNavigate();

  const handleLogout = () => {
    logout(); // Call logout
    navigate('/'); // Redirect to home after logout
  };

  return (
    <Layout>
      <div className="dashboard-content">
        <h1>Welcome, {currentUser?.name || 'User'}!</h1>
        <p>Your email: {currentUser?.email}</p>

        <button onClick={handleLogout} className="logout-button">
          Logout
        </button>
      </div>
    </Layout>
  );
};

export default Dashboard;
