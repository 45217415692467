import React from 'react';
import './Modal.css'; // Create a CSS file to style the modal

const Modal = ({ isOpen, onClose, title, content }) => {
  if (!isOpen) return null; // Modal will only render when isOpen is true

  return (
    <div className="modal-overlay" onClick={onClose}>
      <div className="modal-content" onClick={(e) => e.stopPropagation()}>
        <div className="modal-header">
          <h2>{title}</h2>
          <button className="close-btn" onClick={onClose}>
            &times;
          </button>
        </div>
        <div className="modal-body">{content}</div>
      </div>
    </div>
  );
};

export default Modal;
