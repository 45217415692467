import React, { useContext } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome, faHeart, faShoppingCart, faUser, faStar } from '@fortawesome/free-solid-svg-icons'; // Import faStar for reviews
import { CartContext } from '../../Context/CartContext'; // Import CartContext
import './Header.css';

const Header = ({ setActiveSection }) => {
  const { cart } = useContext(CartContext); // Get cart from context

  // Get total number of items in the cart
  const cartItemCount = cart.reduce((total, item) => total + item.quantity, 0);

  return (
    <nav className="mobile-nav">
      <ul className="nav-list">
        <li className="nav-item">
          <button onClick={() => setActiveSection('home')}>
            <FontAwesomeIcon icon={faHome} />
            <span>Home</span>
          </button>
        </li>
        <li className="nav-item">
          <button onClick={() => setActiveSection('favorites')}>
            <FontAwesomeIcon icon={faHeart} />
            <span>Favorites</span>
          </button>
        </li>
        <li className="nav-item">
          <button onClick={() => setActiveSection('cart')}>
            <FontAwesomeIcon icon={faShoppingCart} />
            <span>Cart</span>
            {cartItemCount > 0 && <span className="cart-count">{cartItemCount}</span>} {/* Cart count */}
          </button>
        </li>
        <li className="nav-item">
          <button onClick={() => setActiveSection('account')}>
            <FontAwesomeIcon icon={faUser} />
            <span>Account</span>
          </button>
        </li>
        <li className="nav-item">
          <button onClick={() => setActiveSection('reviews')}> {/* Added reviews section */}
            <FontAwesomeIcon icon={faStar} />
            <span>Reviews</span>
          </button>
        </li>
      </ul>
    </nav>
  );
};

export default Header;
