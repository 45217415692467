import React, { useState, useEffect } from 'react';
import AdminHeader from './Header/AdminHeader'; // Import AdminHeader
import Home from './Home/Home'; // Import Home component

const AdminDashboard = () => {
  const [activeSection, setActiveSection] = useState('home'); // Default to 'home'

  // Load the last visited section from localStorage on mount
  useEffect(() => {
    const lastSection = localStorage.getItem('activeSection');
    if (lastSection) {
      setActiveSection(lastSection);
    }
  }, []);

  // Update localStorage whenever the active section changes
  useEffect(() => {
    localStorage.setItem('activeSection', activeSection);
  }, [activeSection]);

  return (
    <div>
      <AdminHeader setActiveSection={setActiveSection} /> {/* Pass setActiveSection */}
      <div className="admin-content">
        {activeSection === 'home' && <Home />} {/* Render Home if 'home' is active */}
      </div>
    </div>
  );
};

export default AdminDashboard;
