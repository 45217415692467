// src/Pages/Chat/ChatFooter.js
import React from 'react';

export const ChatFooter = ({ input, setInput, handleSend }) => {
  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      handleSend();
    }
  };

  return (
    <div className="chat-footer">
      <input
        type="text"
        value={input}
        placeholder="Type your message..."
        onChange={(e) => setInput(e.target.value)}
        onKeyDown={handleKeyDown} // Handle Enter key press
      />
      <button onClick={handleSend}>Send</button>
    </div>
  );
};
