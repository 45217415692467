import React from 'react';
import { reviews } from '../Utils/Reviews';  // Import reviews mock data
import { products } from '../Utils/Products';  // Import product details mock data
import './Review.css';

const Review = () => {
  return (
    <div className="review-section">
      <h3>Customer Reviews</h3>
      <div className="review-list">
        {reviews.map((productReview) => {
          const product = products.find((product) => product.id === productReview.productId);

          return (
            <div key={productReview.productId} className="product-review">
              {/* Product Information */}
              <div className="product-info">
                <img src={product.image} alt={product.name} className="product-image" />
                <div className="product-details">
                  <h2>{product.name}</h2>
                  <p>Price: {product.price}</p>
                </div>
              </div>

              {/* Reviews for this product */}
              <div className="review-list">
                {productReview.reviews.map((review) => (
                  <div key={review.id} className="review">
                    <h4>{review.author}</h4>
                    <div className="rating">
                      {Array(review.rating).fill().map((_, index) => (
                        <span key={index} className="star">★</span>
                      ))}
                    </div>
                    <p>{review.comment}</p>
                  </div>
                ))}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Review;
