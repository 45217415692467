// src/ProductSection/FilterableProductList.js
import React, { useState, useContext } from 'react';
import ProductSection from './ProductSection';
import { burgers, fries, fruitParfaits, drinks, milkshakes } from '../Utils/Products';
import { CartContext } from '../Context/CartContext'; // Import CartContext
import { NotificationContext } from '../Context/NotificationContext'; // Import NotificationContext
import './FilterableProductList.css';

const categories = {
  Burger: burgers,
  Fries: fries,
  "Fruit Parfait": fruitParfaits,
  Drinks: drinks,
  Milkshakes: milkshakes,
};

const FilterableProductList = () => {
  const [selectedCategory, setSelectedCategory] = useState("Fries");
  const { addToCart } = useContext(CartContext);  // Get addToCart function from CartContext
  const { showNotification } = useContext(NotificationContext);  // Get showNotification from NotificationContext

  const handleAddToCart = (product, quantity) => {
    // Ensure we're passing the correct product to the cart
    addToCart(product, quantity);
    showNotification(`${product.name} added to cart!`, 'success');  // Show notification
  };

  return (
    <div>
      {/* Filter buttons */}
      <div className="filter-buttons">
        {Object.keys(categories).map((category) => (
          <button
            key={category}
            className={`filter-button ${category === selectedCategory ? 'active' : ''}`}
            onClick={() => setSelectedCategory(category)}
          >
            {category}
          </button>
        ))}
      </div>

      {/* Display the selected category */}
      <ProductSection title={selectedCategory} products={categories[selectedCategory]} onAddToCart={handleAddToCart} />
    </div>
  );
};

export default FilterableProductList;
