import React, { useContext } from 'react';
import { FavoritesContext } from '../../Context/FavoritesContext';
import { CartContext } from '../../Context/CartContext'; // To allow adding favorites to the cart
import './Favorites.css';

const Favorites = () => {
  const { favorites } = useContext(FavoritesContext);
  const { addToCart } = useContext(CartContext); // Add favorite products to the cart

  return (
    <div className="favorites-container">
      <h2>Your Favorites</h2>
      {favorites.length > 0 ? (
        <div className="favorites-grid">
          {favorites.map((product) => (
            <div key={product.id} className="favorite-item">
              <img src={product.image} alt={product.name} className="favorite-image" />
              <div className="favorite-info">
                <h3>{product.name}</h3>
                <p>${product.price.toFixed(2)}</p>
                <button className="add-to-cart-btn" onClick={() => addToCart(product, 1)}>
                  Add to Cart
                </button>
              </div>
            </div>
          ))}
        </div>
      ) : (
        <p>You have no favorite products yet.</p>
      )}
    </div>
  );
};

export default Favorites;
