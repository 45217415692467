import React from 'react';
import { Pie } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
} from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import './Orders.css';

// Register chart elements
ChartJS.register(ArcElement, Tooltip, Legend, ChartDataLabels);

const Orders = ({ onOpenModal }) => {
  // Mock data for the pie chart
  const orderData = {
    labels: ['Pending', 'Accepted', 'Delivered', 'Declined'],
    datasets: [
      {
        label: 'Order Status',
        data: [10, 5, 20, 3], // Example data for orders
        backgroundColor: ['#FF6384', '#36A2EB', '#FFCE56', '#E74C3C'],
        hoverBackgroundColor: ['#FF6384', '#36A2EB', '#FFCE56', '#E74C3C'],
      },
    ],
  };

  const options = {
    plugins: {
      datalabels: {
        display: true,
        color: 'white',
        formatter: (value) => `${value}`, // Display the exact value
        font: {
          weight: 'bold',
          size: 14,
        },
      },
      legend: {
        position: 'bottom',
      },
      maintainAspectRatio: false, // Disable the aspect ratio to control the size
    },
  };

  // Handlers for opening modals with specific content
  const handleViewPendingOrders = () => {
    onOpenModal('Pending Orders', <p>This is where the pending orders will be displayed.</p>);
  };

  const handleAcceptNewOrders = () => {
    onOpenModal('New Orders', <p>This is where the new orders to accept will be displayed.</p>);
  };

  const handleDeclineOrders = () => {
    onOpenModal('Declined Orders', <p>This is where the declined orders will be displayed.</p>);
  };

  return (
    <div className="orders-container">
      <div className="card">
        <h2>Orders</h2>
        {/* Set the width and height to control the size */}
        <div style={{ width: '400px', height: '350px' }}>
          <Pie data={orderData} options={options} />
        </div>

        <div className="order-status-buttons">
          <h3>Manage Orders</h3>
          <button className="order-btn pending-btn" onClick={handleViewPendingOrders}>
            View Pending Orders
          </button>
          <button className="order-btn accept-btn" onClick={handleAcceptNewOrders}>
            Accept New Orders
          </button>
          <button className="order-btn decline-btn" onClick={handleDeclineOrders}>
            Decline Orders
          </button>
        </div>
      </div>
    </div>
  );
};

export default Orders;
