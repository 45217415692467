import React, { createContext, useState, useEffect } from 'react';
import { AuthApi } from './AuthApi'; // Import the Auth API functions
import { useNavigate } from 'react-router-dom'; // For navigation to dashboard

// Create AuthContext
export const AuthContext = createContext();

// AuthProvider component to wrap around the app
export const AuthProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate(); // Use navigate to redirect

  // Check if user is already logged in (e.g., check localStorage or API)
  useEffect(() => {
    const checkLoginStatus = async () => {
      try {
        const user = await AuthApi.getCurrentUser();
        setCurrentUser(user);
      } catch (error) {
      } finally {
        setLoading(false);
      }
    };
    checkLoginStatus();
  }, []);

  // Login function
  const login = async (email, password) => {
    try {
      const data = await AuthApi.login(email, password);
      setCurrentUser(data.user);
      localStorage.setItem('authUser', JSON.stringify(data.user)); // Save to local storage
      localStorage.setItem('authToken', data.token); // Save JWT token

      // Check the role and navigate accordingly
      if (data.user.role === 'admin') {
        navigate('/admin-dashboard'); // Redirect to admin dashboard
      } else {
        navigate('/dashboard'); // Redirect to regular user dashboard
      }
    } catch (error) {
      throw new Error(error.message); // Pass the specific error message
    }
  };

  // Signup function
  const signup = async (name, email, password) => {
    try {
      const user = await AuthApi.signup(name, email, password);
      setCurrentUser(user);
      localStorage.setItem('authUser', JSON.stringify(user)); // Save to local storage
    } catch (error) {
      throw new Error(error.message); // Pass the specific error message
    }
  };

  // Logout function
  const logout = () => {
    setCurrentUser(null);
    localStorage.removeItem('authUser');
    navigate('/'); // Redirect to home after logout
  };

  return (
    <AuthContext.Provider value={{ currentUser, login, signup, logout }}>
      {!loading ? children : <div>Loading...</div>}
    </AuthContext.Provider>
  );
};
