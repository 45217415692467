import React, { useState, useEffect, useContext } from 'react';
import './ProductSection.css';
import { CartContext } from '../Context/CartContext';
import { FavoritesContext } from '../Context/FavoritesContext'; // Import FavoritesContext

const ProductSection = ({ title, products }) => {
  const [quantities, setQuantities] = useState({});
  const { addToCart } = useContext(CartContext);
  const { addToFavorites } = useContext(FavoritesContext); // Access Favorites Context

  // Reset quantities when products change (i.e., when a new category is selected)
  useEffect(() => {
    setQuantities({});
  }, [products]);

  const handleIncrease = (product) => {
    setQuantities(prevQuantities => {
      const newQuantity = (prevQuantities[product.id] || 0) + 1;

      // Add to cart and also add to favorites based on user interest
      setTimeout(() => {
        addToCart(product, newQuantity);
        addToFavorites(product); // Mark the product as a favorite
      }, 0);

      return {
        ...prevQuantities,
        [product.id]: newQuantity,
      };
    });
  };

  const handleDecrease = (product) => {
    setQuantities(prevQuantities => {
      const currentQuantity = prevQuantities[product.id] || 0;
      const newQuantity = Math.max(currentQuantity - 1, 0);

      if (currentQuantity > 0) {
        setTimeout(() => {
          addToCart(product, newQuantity);
        }, 0);
      }

      return {
        ...prevQuantities,
        [product.id]: newQuantity,
      };
    });
  };

  return (
    <div className="product-section">
      <h2>{title}</h2>
      <div className="product-grid">
        {products.map((product) => (
          <div key={product.id} className="product-card">
            <img src={product.image} alt={product.name} className="product-image" />
            <div className="product-info">
              <h3>{product.name}</h3>
              <p>₦{product.price.toFixed(2)}</p> {/* Updated currency symbol */}

              <div className="quantity-control">
                <button onClick={() => handleDecrease(product)}>-</button>
                <span>{quantities[product.id] || 0}</span>
                <button onClick={() => handleIncrease(product)}>+</button>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ProductSection;
