import React, { useContext } from 'react';
import { AuthContext } from '../../Auth/AuthContext'; // Import AuthContext
import { useNavigate } from 'react-router-dom'; // For navigation
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome, faHistory, faUser, faSignOutAlt } from '@fortawesome/free-solid-svg-icons'; // Updated icon for Orders
import './Header.css'; // Import CSS for header styling

const Header = () => {
  const { logout } = useContext(AuthContext); // Get logout function from context
  const navigate = useNavigate();

  const handleLogout = () => {
    logout(); // Call logout function
    navigate('/'); // Redirect to home after logout
  };

  return (
    <header className="header">
      <nav className="navbar">
        <div className="nav-item" onClick={() => navigate('/dashboard')}>
          <button className="nav-button">
            <FontAwesomeIcon icon={faHome} className="nav-icon" />
            <span className="nav-label">Home</span>
          </button>
        </div>
        <div className="nav-item" onClick={() => navigate('/orders')}>
          <button className="nav-button">
            <FontAwesomeIcon icon={faHistory} className="nav-icon" /> {/* Changed to history icon */}
            <span className="nav-label">Orders</span>
          </button>
        </div>
        <div className="nav-item" onClick={() => navigate('/account')}>
          <button className="nav-button">
            <FontAwesomeIcon icon={faUser} className="nav-icon" />
            <span className="nav-label">Account</span>
          </button>
        </div>
        <div className="nav-item" onClick={handleLogout}>
          <button className="nav-button">
            <FontAwesomeIcon icon={faSignOutAlt} className="nav-icon" />
            <span className="nav-label">Logout</span>
          </button>
        </div>
      </nav>
    </header>
  );
};

export default Header;
