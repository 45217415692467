import React from 'react';
import Header from '../Pages/Header/Header';
import Footer from '../Pages/Footer/Footer';

const Layout = ({ children, setActiveSection }) => {
  return (
    <div className="layout-container">
      <Header setActiveSection={setActiveSection} />
      <main className="main-content">
        {children}
      </main>
      <Footer />
    </div>
  );
};

export default Layout;
