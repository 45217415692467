// src/Sales/SalesActions.js
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMoneyBillWave, faUndoAlt } from '@fortawesome/free-solid-svg-icons';
import './Sales.css';

const SalesActions = ({ onConfirmPayments, onViewHistory }) => {
  return (
    <div className="sales-actions">
      <button className="action-btn" onClick={onConfirmPayments}>
        <FontAwesomeIcon icon={faMoneyBillWave} /> Incoming Payments
      </button>
      <button className="action-btn" onClick={onViewHistory}>
        <FontAwesomeIcon icon={faUndoAlt} /> Payment History
      </button>
    </div>
  );
};

export default SalesActions;
