import React from 'react';

const Total = () => {
  return (
    <div className="card-container">
      <div className="card">
        <h2>Total</h2>
        <p>Total earnings will be displayed here.</p>
      </div>
    </div>
  );
};

export default Total;
