import React, { useState, useContext } from 'react';
import SignIn from './SignIn';
import SignUp from './SignUp';
import ForgotPassword from './ForgotPassword';
import Benefits from './Benefits';
import { AuthContext } from '../../Auth/AuthContext'; // Get AuthContext

const Account = () => {
  const [activePage, setActivePage] = useState('signin');
  const { signup } = useContext(AuthContext); // Get signup from AuthContext

  const switchPage = (page) => {
    setActivePage(page);
  };

  const renderPage = () => {
    switch (activePage) {
      case 'signin':
        return <SignIn switchPage={switchPage} />;
      case 'signup':
        return <SignUp switchPage={switchPage} signup={signup} />;  // Pass signup as prop
      case 'forgotPassword':
        return <ForgotPassword switchPage={switchPage} />;
      default:
        return <SignIn switchPage={switchPage} />;
    }
  };

  return (
    <div style={{ backgroundColor: '#000', padding: '0', margin: '0', minHeight: '100vh' }}>
      {renderPage()}
      <Benefits />  {/* Benefits section stays closely below the form */}
    </div>
  );
};

export default Account;
