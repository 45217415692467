// src/Api/MainApi.js
import axios from 'axios';

const apiUrl = process.env.REACT_APP_API_URL; // Get the backend URL from environment variables

// Function to check the API connection
export const checkApiConnection = async () => {
  try {
    const response = await axios.get(`${apiUrl}/connect`);
    if (response.status === 200) {
      console.log('Backend connection successful:', response.data);
      return { success: true, data: response.data };
    }
  } catch (error) {
    console.error('Error connecting to backend:', error);
    return { success: false, error: error.message };
  }
};

