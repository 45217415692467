// src/Pages/Chat/ChatBody.js
import React, { useRef, useEffect } from 'react';

export const ChatBody = ({ messages, handlePredefinedClick }) => {
  const chatBodyRef = useRef(null);

  // Auto-scroll logic
  useEffect(() => {
    if (chatBodyRef.current) {
      chatBodyRef.current.scrollTop = chatBodyRef.current.scrollHeight;
    }
  }, [messages]);

  return (
    <div className="chat-body" ref={chatBodyRef}>
      {messages.length > 0 ? (
        messages.map((msg, index) => (
          <p
            key={index}
            className={msg.sender === 'user' ? 'user-msg' : msg.sender === 'predefined' ? 'predefined-msg' : 'bot-msg'}
            onClick={() => msg.sender === 'predefined' && handlePredefinedClick(msg.text)}
          >
            {msg.text}
          </p>
        ))
      ) : (
        <p className="placeholder">How can we assist you today?</p>
      )}
    </div>
  );
};
