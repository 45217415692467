import React, { useState, useContext } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faLock, faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import './Account.css';
import { AuthContext } from '../../Auth/AuthContext'; // Import AuthContext for login
import { NotificationContext } from '../../Context/NotificationContext'; // For notifications
import Loader from '../../Loader/Loader'; // Loader

const SignIn = ({ switchPage }) => {
  const [showPassword, setShowPassword] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const { login } = useContext(AuthContext); // Get login function from context
  const { showNotification } = useContext(NotificationContext); // Get notification context
  const [loading, setLoading] = useState(false); // State to handle loader

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true); // Show loader during login
    try {
      await login(email, password); // Call login
    } catch (error) {
      showNotification(error.message, 'error'); // Show specific error from login
    } finally {
      setLoading(false); // Hide loader after login
    }
  };

  return (
    <div className="account-container">
      <h2>Sign In</h2>
      {loading ? (
        <Loader /> // Show loader if loading
      ) : (
        <form onSubmit={handleSubmit}>
          <label>Email:</label>
          <div className="input-wrapper">
            <FontAwesomeIcon icon={faEnvelope} className="input-icon" />
            <input 
              type="email" 
              name="email" 
              placeholder="Enter your email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required 
            />
          </div>

          <label>Password:</label>
          <div className="input-wrapper">
            <FontAwesomeIcon icon={faLock} className="input-icon" />
            <input
              type={showPassword ? "text" : "password"}
              name="password"
              placeholder="Enter your password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
            <FontAwesomeIcon
              icon={showPassword ? faEyeSlash : faEye}
              className="toggle-password-icon"
              onClick={togglePasswordVisibility}
            />
          </div>

          <button type="submit">Sign In</button>
        </form>
      )}
      <p>
        Don't have an account? <button onClick={() => switchPage('signup')}>Sign Up</button>
      </p>
      <p>
        Forgot your password? <button onClick={() => switchPage('forgotPassword')}>Reset Password</button>
      </p>
    </div>
  );
};

export default SignIn;
