import React, { useState } from 'react';
import Orders from './Orders/Orders';
import Sales from './Sales/Sales';
import Total from './Total/Total';
import Modal from '../../Modal/Modal'; 
import './Home.css';

const Home = () => {
  const [isModalOpen, setModalOpen] = useState(false);
  const [modalContent, setModalContent] = useState({ title: '', content: '' });

  const handleOpenModal = (title, content) => {
    setModalContent({ title, content });
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  return (
    <div className="home-content">
      <div className="card-row">
        {/* Only pass modal control functions */}
        <Orders onOpenModal={handleOpenModal} />
        <Sales onOpenModal={handleOpenModal} />
        <Total onOpenModal={handleOpenModal} />
      </div>

      {/* Reusable Modal */}
      <Modal
        isOpen={isModalOpen}
        onClose={handleCloseModal}
        title={modalContent.title}
        content={modalContent.content}
      />
    </div>
  );
};

export default Home;
