import React from 'react';
import { Bar } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  BarElement,
  CategoryScale,
  LinearScale,   // Import LinearScale
  Tooltip,
  Legend,
} from 'chart.js';
import './Sales.css';

// Register the necessary components including LinearScale
ChartJS.register(BarElement, CategoryScale, LinearScale, Tooltip, Legend);

const SalesChart = ({ salesData, filter }) => {
  const options = {
    plugins: {
      legend: { position: 'top' },
      tooltip: { enabled: true },
    },
    scales: { y: { beginAtZero: true } },
    maintainAspectRatio: false,
    barThickness: 20,
  };

  return salesData ? (
    <div style={{ width: '400px', height: '350px' }}>
      <Bar
        data={{
          labels: ['Incoming Payment', 'Confirmed Payment', 'Refund Request'],
          datasets: [
            {
              label: `${filter.charAt(0).toUpperCase() + filter.slice(1)} Sales Data`,
              data: [salesData.incoming, salesData.confirmed, salesData.refund],
              backgroundColor: ['#4bc0c0', '#36A2EB', '#FF6384'],
              hoverBackgroundColor: ['#36A2EB', '#4bc0c0', '#FF6384'],
              borderRadius: 10,
              borderWidth: 3,
              borderColor: '#fff',
            },
          ],
        }}
        options={options}
      />
    </div>
  ) : (
    <p>Loading sales data...</p>
  );
};

export default SalesChart;
