import React from 'react';
import { motion } from 'framer-motion'; // Import Framer Motion for animations
import './Hero.css';  // Custom CSS for styling
import heroImage from '../../Assets/Hero.jpg'; // Import the hero image

const Hero = () => {
  return (
    <section className="hero">
      <motion.div
        className="hero-image-container"
        initial={{ opacity: 0, scale: 1.2 }} // Start with a slight zoom and fade-in
        animate={{ opacity: 1, scale: 1 }} // Smoothly scale down to normal size and fade in
        transition={{ duration: 1.5, ease: 'easeOut' }} // Slow transition for a natural look
      >
        <img src={heroImage} alt="Khadz Burger" className="hero-image" />
      </motion.div>
    </section>
  );
};

export default Hero;
