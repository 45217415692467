// src/Sales/Sales.js
import React, { useState, useEffect } from 'react';
import SalesApi from '../../../Api/SalesApi'; 
import SalesFilters from './SalesFilters';
import SalesChart from './SalesChart';
import SalesActions from './SalesActions';
import './Sales.css';

const Sales = ({ onOpenModal }) => {
  const [salesData, setSalesData] = useState(null);
  const [filter, setFilter] = useState('daily');

  useEffect(() => {
    const fetchSalesData = async () => {
      try {
        const data = await SalesApi.getSalesData(filter);
        setSalesData(data);
      } catch (error) {
        console.error('Failed to fetch sales data:', error);
      }
    };

    fetchSalesData();
  }, [filter]);

  const handleConfirmPayments = async () => {
    try {
      const amount = salesData.incoming;
      const response = await SalesApi.confirmPayment(filter, 'incoming', amount);
      setSalesData(response.salesData);
      onOpenModal('Success', `Confirmed ${amount} incoming payments.`);
    } catch (error) {
      console.error('Error confirming payments:', error);
      onOpenModal('Error', 'Failed to confirm payments.');
    }
  };

  const handleViewHistory = async () => {
    try {
      const historyData = await SalesApi.getHistory();
      onOpenModal(
        'Payment History',
        historyData.map((item, index) => (
          <p key={index}>
            {`${item.amount} confirmed for ${item.filter} on ${new Date(item.date).toLocaleDateString()}`}
          </p>
        ))
      );
    } catch (error) {
      console.error('Error fetching history:', error);
      onOpenModal('Error', 'Failed to fetch payment history.');
    }
  };

  return (
    <div className="sales-container">
      <div className="card">
        <h2>Sales</h2>

        {/* Filter Component */}
        <SalesFilters filter={filter} setFilter={setFilter} />

        {/* Chart Component */}
        <SalesChart salesData={salesData} filter={filter} />

        {/* Actions Component */}
        <SalesActions
          onConfirmPayments={handleConfirmPayments}
          onViewHistory={handleViewHistory}
        />
      </div>
    </div>
  );
};

export default Sales;
