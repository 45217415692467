import { useState, useContext } from 'react';
import { CartContext } from '../../Context/CartContext';
import { deliveryFee, bankDetails } from '../../Utils/CheckoutData';

export const useCheckout = () => {
  const { cart, clearCart } = useContext(CartContext); // Clear cart on checkout success
  const [paymentMethod, setPaymentMethod] = useState('');
  const [deliveryOption, setDeliveryOption] = useState('');
  const [loading, setLoading] = useState(false);
  const [orderStatus, setOrderStatus] = useState('');
  const [userName, setUserName] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [address, setAddress] = useState('');

  // Calculate total cart price
  const totalPrice = cart.reduce((total, item) => total + item.price * item.quantity, 0);

  // Total price including delivery fee if applicable
  const finalPrice = deliveryOption === 'delivery' ? totalPrice + deliveryFee : totalPrice;

  // Handle Payment Submission
  const handlePayment = () => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
      setOrderStatus('Pending Payment Confirmation');
    }, 2000);
  };

  // Generate random 5-letter order ID
  const generateOrderId = () => {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
    let orderId = '';
    for (let i = 0; i < 5; i++) {
      orderId += characters.charAt(Math.floor(Math.random() * characters.length));
    }
    return orderId;
  };

  return {
    cart,
    paymentMethod,
    setPaymentMethod,
    deliveryOption,
    setDeliveryOption,
    loading,
    orderStatus,
    userName,
    setUserName,
    phoneNumber,
    setPhoneNumber,
    address,
    setAddress,
    finalPrice,
    bankDetails,
    deliveryFee,
    handlePayment,
    clearCart, // Clear cart after order success
    generateOrderId, // Generate the Order ID
  };
};
