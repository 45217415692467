// src/Utils/reviews.js

export const reviews = [
    {
      productId: 1,
      reviews: [
        { id: 1, author: 'Temmy', rating: 5, comment: 'The Classic Fries are amazing! Super crispy and tasty.' },
        { id: 2, author: 'Richie', rating: 4, comment: 'Loved the flavor, but a little too salty for my taste.' }
      ]
    },
    {
      productId: 2,
      reviews: [
        { id: 3, author: 'Dolapo', rating: 5, comment: 'Cheese Fries were absolutely delicious, would definitely order again!' },
        { id: 4, author: 'Tola', rating: 3, comment: 'Good fries, but I think the cheese could be better.' }
      ]
    }
  ];
  