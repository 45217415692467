import React, { useContext } from 'react';
import { Navigate } from 'react-router-dom';
import { AuthContext } from '../Auth/AuthContext';

const PrivateRoute = ({ children, role }) => {
  const { currentUser } = useContext(AuthContext);

  // Check if user is logged in and if their role matches
  if (!currentUser) {
    return <Navigate to="/admin-login" />; // Redirect to admin login if not logged in
  }

  // Redirect based on role
  if (role === 'admin' && currentUser.role !== 'admin') {
    return <Navigate to="/dashboard" />; // Non-admin users cannot access admin routes
  }

  if (role === 'user' && currentUser.role !== 'user') {
    return <Navigate to="/admin-dashboard" />; // Admin users cannot access user routes
  }

  return children; // User role is correct, render the protected content
};

export default PrivateRoute;
