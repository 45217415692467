import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import './Account.css';

const ForgotPassword = ({ switchPage }) => {
  return (
    <div className="account-container">
      <h2>Reset Password</h2>
      <form>
        <label>Email:</label>
        <div className="input-wrapper">
          <FontAwesomeIcon icon={faEnvelope} className="input-icon" />
          <input
            type="email"
            name="email"
            placeholder="Enter your email"
            required
          />
        </div>

        <button type="submit">Reset Password</button>
      </form>
      <p>
        Remembered your password? <button onClick={() => switchPage('signin')}>Sign In</button>
      </p>
    </div>
  );
};

export default ForgotPassword;
