import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { useCheckout } from './useCheckout';
import './Checkout.css';

const Checkout = ({ setActiveSection }) => {
  const {
    cart, paymentMethod, setPaymentMethod, deliveryOption, setDeliveryOption, loading, orderStatus, userName,
    setUserName, phoneNumber, setPhoneNumber, address, setAddress, finalPrice, bankDetails, deliveryFee, handlePayment,
    clearCart, generateOrderId,
  } = useCheckout();

  const [errors, setErrors] = useState({});
  const [isFormValid, setIsFormValid] = useState(false);

  // Validate the form whenever any input changes
  useEffect(() => {
    const newErrors = {};

    // Name validation
    if (userName.trim() === '') {
      newErrors.userName = 'Name is required';
    }

    // Phone number validation
    if (phoneNumber.trim() === '') {
      newErrors.phoneNumber = 'Phone number is required';
    }

    // Address validation (only if delivery option is selected)
    if (deliveryOption !== 'pickup' && address.trim() === '') {
      newErrors.address = 'Address is required for delivery';
    }

    // Payment method validation
    if (paymentMethod === '') {
      newErrors.paymentMethod = 'Payment method is required';
    }

    setErrors(newErrors);
    setIsFormValid(Object.keys(newErrors).length === 0);
  }, [userName, phoneNumber, paymentMethod, deliveryOption, address]);

  const handlePaymentAndNavigate = () => {
    handlePayment();
    setTimeout(() => {
      clearCart();
      const orderId = generateOrderId();
      setActiveSection('confirmation', { orderId });
    }, 2000);
  };

  return (
    <div className="checkout-container">
      <h2>Checkout</h2>

      {/* Cart Summary */}
      <div className="cart-summary">
        <h3>Order Summary</h3>
        {cart.map(item => (
          <div key={item.id} className="cart-item">
            <h4>{item.name}</h4>
            <p>Quantity: {item.quantity}</p>
            <p>Price: ₦{item.price.toFixed(2)}</p>
          </div>
        ))}
        <div className="total">
          <h3>Total: ₦{finalPrice.toFixed(2)}</h3>
        </div>
      </div>

      {/* User Details */}
      <div className="user-details">
        <h3>Provide Your Details</h3>
        <label>
          Name:
          <input
            type="text"
            value={userName}
            onChange={(e) => setUserName(e.target.value)}
            required
          />
          {errors.userName && <p className="error">{errors.userName}</p>}
        </label>
        <label>
          Phone Number:
          <input
            type="text"
            value={phoneNumber}
            onChange={(e) => setPhoneNumber(e.target.value)}
            required
          />
          {errors.phoneNumber && <p className="error">{errors.phoneNumber}</p>}
        </label>
        <label>
          Address/Instructions:
          <textarea
            value={address}
            onChange={(e) => setAddress(e.target.value)}
            disabled={deliveryOption === 'pickup'}
            placeholder="Enter your address for delivery or leave blank for pickup / You can leave an instruction if there is any."
          ></textarea>
          {deliveryOption !== 'pickup' && errors.address && <p className="error">{errors.address}</p>}
        </label>
      </div>

      {/* Payment Method Selection */}
      <div className="payment-method">
        <h3>Select Payment Method</h3>
        <label>
          <input
            type="radio"
            value="transfer"
            checked={paymentMethod === 'transfer'}
            onChange={() => setPaymentMethod('transfer')}
          />
          Bank Transfer
        </label>
        <label>
          <input
            type="radio"
            value="payOnDelivery"
            checked={paymentMethod === 'payOnDelivery'}
            onChange={() => setPaymentMethod('payOnDelivery')}
          />
          Pay on Delivery
        </label>
        <label>
          <input
            type="radio"
            value="pickup"
            checked={paymentMethod === 'pickup'}
            onChange={() => setPaymentMethod('pickup')}
          />
          Pick Up
        </label>
        {errors.paymentMethod && <p className="error">{errors.paymentMethod}</p>}
      </div>

      {/* Delivery or Pickup Option */}
      {(paymentMethod === 'transfer' || paymentMethod === 'payOnDelivery') && (
        <div className="delivery-option">
          <h3>Delivery or Pickup</h3>
          <label>
            <input
              type="radio"
              value="delivery"
              checked={deliveryOption === 'delivery'}
              onChange={() => setDeliveryOption('delivery')}
            />
            Delivery (Delivery Fee: ₦{deliveryFee})
          </label>
          <label>
            <input
              type="radio"
              value="pickup"
              checked={deliveryOption === 'pickup'}
              onChange={() => setDeliveryOption('pickup')}
            />
            Pick Up from Store
          </label>
        </div>
      )}

      {/* Transfer Payment Details */}
      {paymentMethod === 'transfer' && (
        <div className="transfer-details">
          <h3>Banking Information</h3>
          <p>Bank: {bankDetails.bank}</p>
          <p>Account Name: {bankDetails.accountName}</p>
          <p>Account Number: {bankDetails.accountNumber}</p>
          <p>Total Amount: ₦{finalPrice.toFixed(2)}</p>
          <label>
            Upload Payment Receipt:
            <input type="file" />
          </label>
        </div>
      )}

      {/* Checkout Button with Total Price */}
      <button
        className="payment-btn"
        onClick={handlePaymentAndNavigate}
        disabled={!isFormValid} // Disable button if form is not valid
      >
        Checkout - ₦{finalPrice.toFixed(2)}
      </button>

      {/* Loader */}
      {loading && (
        <div className="loader">
          <FontAwesomeIcon icon={faSpinner} spin /> Processing Payment...
        </div>
      )}

      {/* Order Status */}
      {orderStatus && <div className="order-status">{orderStatus}</div>}
    </div>
  );
};

export default Checkout;
