import React, { useState } from 'react'; // Import useState
import { Link } from 'react-router-dom';
import './AdminHeader.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import Loader from '../../Loader/Loader'; // Import your loader component

const AdminHeader = ({ setActiveSection }) => {
  const [loading, setLoading] = useState(false); // State for showing the loader

  const handleLogout = () => {
    setLoading(true); // Show the loader
    setTimeout(() => {
      localStorage.removeItem('authUser');
      localStorage.removeItem('authToken');
      setLoading(false);
    }, 2000); // Simulate delay before logging out
  };

  return (
    <header className="admin-header">
      <div className="logo">
        <i className="fas fa-hamburger"></i> <span className="logo-text">Admin</span>
      </div>

      <nav className="nav-links">
        <Link to="#" onClick={() => setActiveSection('home')}>
          <i className="fas fa-home"></i>
          <span>Home</span>
        </Link>
        <Link to="#" onClick={() => setActiveSection('products')}>
          <i className="fas fa-box"></i>
          <span>Products</span>
        </Link>
        <Link to="#" onClick={() => setActiveSection('settings')}>
          <i className="fas fa-cog"></i>
          <span>Settings</span>
        </Link>
        <button onClick={handleLogout} className="logout-btn" disabled={loading}>
          {loading ? <Loader /> : <><i className="fas fa-sign-out-alt"></i><span>Logout</span></>}
        </button>
      </nav>
    </header>
  );
};

export default AdminHeader;
